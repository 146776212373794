import {
  Button,
  Container,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import OrganizationCard from '../../components/OrganizationCard'
import { mascDocument } from '../../helpers'
import { IPaymentConsent } from '../../types'

interface IProps {
  item: any

  organization: any
  consent: IPaymentConsent
  handleSelectHolder: (data: any) => any
  handleUpdateConsent: (data: any) => any
}

const PaymentMethodSelector = ({
  organization,
  consent,
  handleUpdateConsent,
}: IProps) => {
  const history = useHistory()
  const [paymentMethod, setPaymentMethod] = useState<string | null>(
    consent?.payment?.details?.type ?? null
  )
  const [pixMessage, setPixMessage] = useState<string | undefined>(
    consent?.remittanceInformation
  )
  const [description] = useState<string | undefined>(undefined)

  return (
    <Container id="payment-method-selector-container">
      <Typography variant="h5" id="payment-method-selector-title">
        Forma de Pagamento
      </Typography>

      <Grid container spacing={3} justifyContent="flex-end">
        <Grid
          item
          xs={12}
          style={{
            marginBottom: 8,
          }}
        >
          <OrganizationCard
            xs={12}
            sm={12}
            md={12}
            lg={12}
            item={organization}
            onClick={(data: any) => {}}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            marginBottom: 8,
          }}
        >
          <Typography className="label">Forma de Pagamento</Typography>
          <Select
            value={paymentMethod}
            onChange={(e) => {
              setPaymentMethod(e.target.value)
            }}
            fullWidth
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value={'PIX'}>PIX</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} justifyContent="stretch">
          <Typography className="label">Chave PIX do Beneficiário</Typography>
          <Typography className="payment-result-details-value">
            {mascDocument(consent?.payment?.details?.proxy)}
          </Typography>
        </Grid>
        {paymentMethod === 'PIX' && (
          <Grid item xs={12}>
            <Typography className="label">Mensagem Pix:</Typography>
            <TextField
              fullWidth
              variant="standard"
              value={pixMessage}
              multiline
              minRows={1}
              maxRows={3}
              onChange={(e: any) => setPixMessage(e.target.value)}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={4}
          lg={3}
          style={{
            marginBottom: 8,
          }}
          justifyContent="stretch"
        >
          <Button
            onClick={(e: any) => {
              handleUpdateConsent({
                ...consent,
                organizationId: organization.organizationId,
                remittanceInformation: pixMessage,
                payment: {
                  ...consent.payment,
                  type: paymentMethod,
                  details: {
                    ...consent.payment.details,
                  },
                  description,
                },
              })
              history.push('/checkout')
            }}
            fullWidth
            size="large"
            color="primary"
            variant="contained"
          >
            Continuar
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}

export default PaymentMethodSelector
