/* eslint-disable */

import env from 'react-dotenv'
interface IToken {
  access_token: string
  token_type: string
}
class OrganizationsService {
  static token: IToken | any = null
  static getOrganizationDetails(
    Authorization: string,
    AuthorizationServerId: string
  ): any {
    return fetch(
      `${env.REACT_APP_API_URL}/organization/${AuthorizationServerId}`,
      {
        headers: {
          Authorization,
          'Access-Control-Request-Headers': 'fcamara-internal',
        },
        mode: 'cors',
      }
    )
      .then((response: any) => response.json())
      .then(({ data }: any) => data)
      .catch((error: any) => {
        console.log(error)
      })
  }
  static listPaymentOrganizations(session: string): any {
    return fetch(`${env.REACT_APP_API_URL}/organizations/${session}`, {})
      .then((response: any) => {
        return response.json()
      })
      .then((response: any) => {
        const { data } = response
        return data
      })
      .catch((error: any) => {
        console.log(error)
        return []
      })
  }
}

export default OrganizationsService
