import * as React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import AccountHolderSelector from '../pages/AccountHolderSelector'
import Checkout from '../pages/Checkout'
import ErrorPage from '../pages/Error'
import PaymentConsentResult from '../pages/PaymentConsentResult'
import PaymentResult from '../pages/PaymentResult'
import PaymentTypeSelector from '../pages/PaymentTypeSelector'
import RedirectToOrganization from '../pages/RedirectToOrganization'

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/error" component={ErrorPage} />
        <Route
          exact
          path="/select-payment-type"
          component={PaymentTypeSelector}
        />
        <Route
          exact
          path="/consent-redirect"
          component={RedirectToOrganization}
        />
        <Route
          exact
          path="/payment-consent-result"
          component={PaymentConsentResult}
        />
        <Route path="/payment-result" component={PaymentResult} />
        <Route exact path="/checkout" component={Checkout} />
        <Route exact path="/" component={AccountHolderSelector} />
      </Switch>
    </BrowserRouter>
  )
}

export default Routes
