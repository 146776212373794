import { CheckCircle, HighlightOff } from '@mui/icons-material'
import { LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { CircularProgress, Grid, Typography } from '@mui/material'
import React from 'react'
import PaymentService from '../../services/payment'

class PaymentConsentResult2 extends React.Component {
  state = {
    consent: null,
    consentAuthorised: null,
    paymentStatus: null,
    redirectUrl: '',
  }
  pooler: any = null
  sessionId = ''
  history: any = null
  query: any = null
  constructor(props: any) {
    super(props)
    this.history = props.history
    this.sessionId = props.location.search
      .replace('?sessionId=', '')
      .split('&')[0]
    if (/\/\w{8}-\w{4}-\w{4}-\w{4}-\w{12}\/[a-z_]+$/i.test(this.sessionId)) {
      this.sessionId = this.sessionId.replace(
        /\/\w{8}-\w{4}-\w{4}-\w{4}-\w{12}\/[a-z_]+$/i,
        ''
      )
    }
  }

  getSession() {
    clearTimeout(this.pooler)
    return PaymentService.getPaymentSession(this.sessionId).then(
      (data: any) => {
        if (!data) {
          this.setState({
            consentAuthorised: data?.consentStatus === 'REJECTED',
            paymentStatus: undefined,
          })
        }

        if (data?.paymentStatus) {
          if (data?.paymentStatus === 'RJCT') {
            //redicecionar para a tea de erro
            this.history.push(`/payment-result?sessionId=${this.sessionId}`, {
              error: {
                ...data,
                code: 'PAYMENT_REJECTED',
                title: 'PAYMENT_REJECTED',
                message: 'Pagamento Rejeitado pela instituição',
                callbackUrl: this.state.redirectUrl,
              },
            })
          }

          if (data?.paymentStatus === 'ACCC' || data?.paymentStatus === 'ACSC' || data.paymentStatus === 'SCHD') {
            this.history.push(
              `/payment-result?sessionId=${this.sessionId}`,
              data
            )
          } else {
            this.pooler = setTimeout(() => this.getSession(), 3000)
          }
        } else {
          if (data?.consentStatus === 'AUTHORISED') {
            this.createPayment()
          }
        }
        this.setState({
          consent: data,
          consentAuthorised: data?.consentStatus === 'AUTHORISED',
          paymentStatus: data?.paymentStatus,
          redirectUrl: data?.redirectUrl,
        })
      }
    )
  }

  createPayment() {
    return PaymentService.createPixPayment(this.sessionId).then((data: any) => {
      if (!data) {
        this.history.push(`/payment-result?sessionId=${this.sessionId}`, {
          code: 'CNS-002',
          message: 'Não foi possível iniciar o pagamento',
          callStack: data,
          callbackUrl: this.state.redirectUrl,
        })
      }

      this.setState({ paymentStatus: data?.paymentStatus })

      if (data.statusCode?.toString() !== '200') {
        //throw { ...data, message: data.detail }
        this.history.push(`/payment-result?sessionId=${this.sessionId}`, {
          error: {
            ...data,
            message: data.detail,
            callbackUrl: this.state.redirectUrl,
          },
        })
        return
      }

      this.getSession()
    })
  }

  componentDidMount() {
    this.getSession()
  }

  render() {
    const { consent, consentAuthorised, paymentStatus } = this.state

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Typography variant="h4" id="consent-result-title">
          {consent
            ? consentAuthorised
              ? 'Autorizado'
              : 'Rejeitado'
            : 'Consultando'}
        </Typography>
        <Grid container spacing={2} id="consent-result-container">
          <Grid item xs={12} id="consent-result-icon-container">
            {consent ? (
              consentAuthorised ? (
                <CheckCircle color="success" />
              ) : (
                <HighlightOff color="error" />
              )
            ) : (
              <CircularProgress style={{ width: '7rem', height: '7rem' }} />
            )}
          </Grid>
          <Grid item xs={12} id="consent-result-status-message-container">
            {consentAuthorised ? (
              <Typography variant="h4" id="consent-result-status-message">
                {paymentStatus
                  ? paymentStatus === 'ACCC'
                    ? 'Pagamento Aprovado'
                    : 'Aguardando aprovação do pagamento'
                  : 'Processando Pagamento'}
              </Typography>
            ) : null}
          </Grid>
          {consentAuthorised && (
            <Grid item xs={12} id="consent-result-payment-status-container">
              {paymentStatus && paymentStatus === 'ACCC' ? (
                <CheckCircle
                  color="success"
                  style={{ fontSize: 72, textAlign: 'center', margin: 32 }}
                />
              ) : (
                <CircularProgress />
              )}
            </Grid>
          )}
        </Grid>
      </LocalizationProvider>
    )
  }
  return = (): void => {
    clearInterval(this.pooler)
  }
}

export default PaymentConsentResult2
