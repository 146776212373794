import { Button, Grid, GridSize, Typography } from '@mui/material'
import { CommonProps } from '@mui/material/OverridableComponent'
import React from 'react'
import logoUrlDefault from '../../assets/logo/op.png'
import './style.css'

interface IProps extends CommonProps {
  item: any
  xs?: GridSize
  sm?: GridSize
  md?: GridSize
  lg?: GridSize

  onClick: (data: any) => void
}

const OrganizationCard: React.FC<IProps> = ({
  item,
  onClick,
  xs,
  sm,
  md,
  lg,
  className,
}: IProps) => {
  return (
    <Grid
      className={className}
      id={item?.organizationId?.toString()}
      item
      xs={xs || 12}
      sm={sm || 6}
      md={md || 4}
    >
      <Button fullWidth onClick={() => onClick(item)}>
        <div className="organization-card-content-container">
          <img
            src={item?.logoUrl}
            onError={({currentTarget}) => {
              currentTarget.onerror = null;
              currentTarget.src = logoUrlDefault
            }}
            alt="Logo"
            className="organization-card-logo"
          />
          <Typography className="organization-card-text">
            {item?.organizationName?.toString() ?? ''}
          </Typography>
        </div>
      </Button>
    </Grid>
  )
}

export default OrganizationCard
