import { Container } from '@mui/material'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import App from './App'
import Footer from './components/Footer'
import Header from './components/Header'
import { reducers } from './reducers'

let store = createStore(
  reducers,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__()
)

fetch('/static/custom.errors.json')
  .then(function (response) {
    return response.json()
  })
  .then(function (response) {
    return ((window as any).env.errors = response)
  })

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Header />
      <Container id="application-container" maxWidth="md">
        <App />
      </Container>
      <Footer />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
