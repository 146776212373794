import { CheckCircle, HighlightOff } from '@mui/icons-material'
import { LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { Button, Grid, Paper, Typography } from '@mui/material'
import Locale from 'date-fns/locale/pt-BR'
import React from 'react'
import Error from '../Error/component'

class PaymentConsentResult extends React.Component {
  history: any = null
  state: any = null
  constructor(props: any) {
    super(props)
    this.state = props.location.state
    this.history = props.history
    console.log(props)
  }

  render() {
    const { payment, consentStatus, creditor, redirectUrl, error, paymentStatus } = this.state
    if (error) {
      return <Error {...{ error }} />
    }

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={Locale}>
        <Typography variant="h4" id="payment-result-details-title">
          Resumo do Pagamento
        </Typography>
        <Grid container spacing={2} id="payment-result-details-container">
          <Grid item xs={12} id="payment-result-details-icon-container">
            {consentStatus ? (
              consentStatus === 'AUTHORISED' ? (
                <CheckCircle color="success" style={{}} />
              ) : (
                <HighlightOff color="error" />
              )
            ) : (
              <div />
            )}
          </Grid>
          <Grid item xs={12} id="payment-result-details-paper-container">
            <Paper id="payment-result-details-paper">
              <Grid item xs={12} className="payment-result-details-section">
                <Typography className="label">Valor pago</Typography>
                <Typography
                  id="payment-result-details-payment-value"
                  className="payment-result-details-value"
                >
                  R${' '}
                  {payment?.amount
                    ?.toFixed(2)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                </Typography>
              </Grid>
              <Grid item xs={12} className="payment-result-details-section">
                <Typography className="label">Para:</Typography>
                <Typography className="payment-result-details-value">
                  {creditor.name}
                </Typography>
                <Typography className="payment-result-details-value">
                  {/*Chave PIX: iniciadora@nuvempay.com*/}
                  {creditor.cpfCnpj}
                </Typography>
              </Grid>
              <Grid item xs={12} className="payment-result-details-section">
                <Typography className="label">Forma de pagamento:</Typography>
                <Typography className="payment-result-details-value">
                  {payment?.type}
                </Typography>
              </Grid>
              <Grid item xs={12} className="payment-result-details-section">
                <Typography className="label">Mensagem Pix:</Typography>
                <Typography className="payment-result-details-value">
                  {this.state.remittanceInformation}
                </Typography>
              </Grid>
              <Grid item xs={12} className="payment-result-details-section">
                <Typography className="label">Transação:</Typography>
                <Typography className="payment-result-details-value">
                  {this.state.transactionIdentification}
                </Typography>
              </Grid>
              <Grid item xs={12} className="payment-result-details-section">
                <Typography className="label">
                  {paymentStatus === 'SCHD' ? 'Pagamento agendado para:' : 'Data de pagamento:'}
                </Typography>
                <Typography className="payment-result-details-value">
                  {new Date(this.state.createdate).toLocaleDateString('pt-br')}
                </Typography>
              </Grid>
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            className="payment-result-details-section"
            alignSelf={'flex-end'}
          >
            <Button
              fullWidth
              size="large"
              variant="contained"
              onClick={() => {
                window.location.href = redirectUrl
              }}
            >
              Retornar
            </Button>
          </Grid>
        </Grid>
      </LocalizationProvider>
    )
  }
}
export default PaymentConsentResult
