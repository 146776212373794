export interface IToken {
  access_token: string
  token_type: string
}
export interface IPaymentConsent {
  idempotencyKey: string
  redirectUrl: string
  customerId: string
  loggedUser: string
  status?: string
  consentId?: string
  consentStatus?: string

  remittanceInformation?: string
  creditor: {
    personType: string
    cpfCnpj: string
    name: string
  }
  debtor: {
    personType?: string
    cpfCnpj?: string
    name: string
  }
  payment: {
    type: string
    date: Date
    currency: string
    amount: Number
    description?: string
    details: {
      localInstrument: string
      proxy: string
      pixMessage?: string
      type?: string
      creditorAccount: {
        ispb: string
        issuer: string
        number: string
        accountType: string
      }
    }
  }
}

export const ConsentTypes = {
  UPDATE_CONSENT_DETAILS: 'UPDATE_CONSENT_DETAILS',
  CREATED_CONSENT_DETAILS: 'CREATED_CONSENT_DETAILS',
  ERROR_DETAILS: 'ERROR_DETAILS',
}
