import { connect } from 'react-redux'
import { Types } from '../../reducers'
import Component from './component'

const mapStateToProps = (state: any) => {
  return {
    organization: state.selectedHolder,
    consent: state.consent,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    handleSelectMethod: (data: any) =>
      dispatch({ type: Types.SELECT_PAYMENT_METHOD, data }),
    handleConsentCreation: (consent: any) => {},
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
