import env from 'react-dotenv'

class PaymentService {
  static createPaymentConsent({
    sessionId,
    payment,
    organizationId,
    remittanceInformation,
  }: any): any {
    const options: RequestInit = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        organizationId,
        paymentType: payment.type,
        remittanceInformation,
      }),
    }

    return fetch(
      `${env.REACT_APP_API_URL}/start-payment/${sessionId}`,
      options
    ).then((response: any) => response.json())
  }

  static createPixPayment(token: string) {
    const options: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return fetch(`${env.REACT_APP_API_URL}/pix/payment/${token}`, options)
      .then((response: any) => response.json())
      .then((result) => {
        return result.data ?? result
      })
  }

  static async getPaymentSession(token: string): Promise<any> {
    // const options: RequestInit = {
    //   method: 'GET',
    //   mode: 'cors',
    // }
    const consent = await fetch(
      `${env.REACT_APP_API_URL}/start-payment/${token}`
    )
      .then((response: any) => {
        return response.json()
      })
      .then((result) => {
        return result
      })
    return consent
  }
}

export default PaymentService
