import {
  Alert,
  CircularProgress,
  Grid,
  Snackbar,
  Typography,
} from '@mui/material'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { IPaymentConsent } from '../../types'

interface IProps {
  item: any

  organization: any
  consent: IPaymentConsent
  handleSelectMethod: (data: any) => any

  consentError?: any
  remittanceInformation?: string
}

const PaymentMethodSelector = ({
  organization,
  consent,
  handleSelectMethod,
  consentError,
}: IProps) => {
  const history = useHistory()

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    //setError(null)
  }
  useEffect(() => {
    handleSelectMethod({
      ...consent,
      organizationId: organization.organizationId,
    }).then((result: any) => {
      if (result) {
        setTimeout(() => {
          //history.push(result.redirectUrl)
          window.location.href = result.redirectUrl
        }, 1000)
      } else {
        history.push('/error')
      }
    })
  }, [consent, handleSelectMethod, history, organization.organizationId])

  return (
    <Grid container spacing={3} id="redirect-to-organization-container">
      <Grid item xs={12}>
        <Typography id="redirect-to-organization-title" variant="h4">
          Redirecionando para Autorização
        </Typography>

        <Typography variant="h6" id="redirect-to-organization-message">
          Após o envio das informações, você terá até 5 minutos para confirmar o
          pagamento na instituição
        </Typography>
      </Grid>
      <Grid item xs={12} id="redirect-to-organization-org-details-container">
        <img
          src={organization.logoUrl}
          id="redirect-to-organization-org-details-logo"
          alt="Logo"
        />
        <Typography variant="h5" id="redirect-to-organization-org-details-name">
          {organization.organizationName.toString()}
        </Typography>
      </Grid>
      <Grid item xs={12} id="redirect-to-organization-progress-container">
        <CircularProgress id="redirect-to-organization-progress-indicator" />
      </Grid>
      <Snackbar
        open={!!consentError}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert severity="error" onClose={handleClose} sx={{ width: '100%' }}>
          {consentError?.message}
        </Alert>
      </Snackbar>
    </Grid>
  )
}

export default PaymentMethodSelector
