import { connect } from 'react-redux'
import { Types } from '../../reducers'
import { ConsentTypes } from '../../types'
import Component from './component'

const mapStateToProps = (state: any) => {
  return {
    organization: state.selectedHolder,
    consent: state.consent,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    handleConfirmMethod: (data: any) =>
      dispatch({ type: Types.SELECT_PAYMENT_METHOD, data }),
    handleUpdateConsent: (data: any) =>
      dispatch({ type: ConsentTypes.UPDATE_CONSENT_DETAILS, data }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
