import { Search } from '@mui/icons-material'
import { Divider, IconButton, InputBase, Paper } from '@mui/material'
import React from 'react'

const SearchBar: React.FC<any> = (props: any) => {
  return (
    <Paper
      className="search-container"
      elevation={1}
      style={{
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <IconButton
        className="search-icon"
        aria-label="Search"
        style={{ padding: 10 }}
      >
        <Search />
      </IconButton>
      <Divider
        className="search-divider"
        style={{
          width: 1,
          height: 28,
          margin: 4,
        }}
      />
      <InputBase
        className="search-input"
        style={{
          marginLeft: 8,
          flex: 1,
        }}
        placeholder="Localize a Instituição"
        {...props}
      />
    </Paper>
  )
}
export default SearchBar
