import * as React from 'react'
import env from 'react-dotenv'

export interface IProps {}

export interface IState {}

const Footer: React.FC<IProps> = () => {
  if (env.REACT_APP_FOOTER) {
    return (
      <div
        className="footer-container"
        dangerouslySetInnerHTML={{ __html: `${env.REACT_APP_FOOTER}` }}
      ></div>
    )
  }
  return null
}

export default Footer
