import { Container } from '@mui/material'
import { Box } from '@mui/system'
import * as React from 'react'
import env from 'react-dotenv'

export interface IProps {}

export interface IState {}

// function handleLanguage() {
//   let langPt = document.querySelector('.PT')
//   let langEn = document.querySelector('.EN')

//   langPt?.addEventListener('click', function () {
//     langPt?.classList.add('languageActive')
//     langPt?.classList.remove('lang')
//     langEn?.classList.remove('languageActive')
//     langEn?.classList.add('lang')
//   })

//   langEn?.addEventListener('click', function () {
//     langEn?.classList.add('languageActive')
//     langEn?.classList.remove('lang')
//     langPt?.classList.remove('languageActive')
//     langPt?.classList.add('lang')
//     langPt?.classList.remove('PT')
//   })
// }

const Header: React.FC<IProps> = () => {
  return (
    <Box className="header">
      <Container maxWidth="sm" className="header-container">
        <img src={`${env.REACT_APP_LOGO}`} alt="FCamara" />
        <img src="/logo-open-finance.svg" alt="OpenBanking" />
      </Container>
    </Box>
  )
}

export default Header
