import { ConsentTypes } from './types'

export enum Types {
  SET_REDIRECT_URL = 'SET_REDIRECT_URL',
  SELECT_PAYMENT_METHOD = 'SELECT_PAYMENT_METHOD',

  SELECT_ORGANIZATION = 'SELECT_ORGANIZATION',
  SET_ORGANIZATION_LIST = 'SET_ORGANIZATION_LIST',
  UPDATE_GRAVITEE_TOKEN = 'UPDATE_GRAVITEE_TOKEN',
  STORE_PAYMENT_CONSENT = 'STORE_PAYMENT_CONSENT',
  STORE_PAYMENT = 'STORE_PAYMENT',
}
const prevState = {
  accountHolders: [],
}

export const reducers = function (state: any = prevState, action: any) {
  switch (action.type) {
    case Types.UPDATE_GRAVITEE_TOKEN:
      return {
        ...state,
        Authorization: action.data,
      }
    case Types.SELECT_ORGANIZATION:
      return {
        ...state,
        selectedHolder: action.data,
      }
    case Types.SET_ORGANIZATION_LIST:
      return {
        ...state,
        accountHolders: action.data,
      }
    case Types.SELECT_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.data,
      }
    case ConsentTypes.ERROR_DETAILS:
      return {
        ...state,
        error: action.data,
      }
    case ConsentTypes.UPDATE_CONSENT_DETAILS:
      return {
        ...state,
        consent: {
          ...state.consent,
          ...action.data,
        },
      }
    case Types.STORE_PAYMENT_CONSENT:
      return {
        ...state,
        paymentConsent: {
          ...state.consent,
          ...action.data,
        },
      }
    case Types.STORE_PAYMENT:
      return {
        ...state,
        payment: action.data,
      }
    case Types.SET_REDIRECT_URL:
      return {
        ...state,
        callbackRedirectUrl: action.data,
      }
    default:
      return state
  }
}
