import { connect } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { Types } from '../../reducers'
import OrganizationsService from '../../services/organizations'
import PaymentService from '../../services/payment'
import { ConsentTypes } from '../../types'
import Component from './component'

const mapStateToProps = (state: any) => {
  return {
    accountHolders: state.accountHolders,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    handleSelectHolder: (data: any) =>
      dispatch({ type: Types.SELECT_ORGANIZATION, data }),
    handleFetchOrganizations: (sessionId: string) => {
      return OrganizationsService.listPaymentOrganizations(sessionId)
        .then((data: any) => {
          if (!data) {
            throw new Error('Erro')
          }
          dispatch({ type: Types.SET_ORGANIZATION_LIST, data })
          return data
        })
        .catch((e: any) => {
          dispatch({
            type: ConsentTypes.ERROR_DETAILS,
            data: {
              title: 'Erro ao consultar as informações',
              statusCode: 400,
              code: 'ORG-001',
              message: 'Não foi possível consultar as informações da seção',
            },
          })
          return null
        })
    },
    handleLoadSessionConsent: (
      sessionId: string,
      sessionRedirectUrl: string
    ) => {
      return PaymentService.getPaymentSession(sessionId)
        .then((data: any) => {
          if (!data) {
            throw new Error('Erro')
          }
          dispatch({ type: Types.SET_REDIRECT_URL, data: data.redirectUrl })
          localStorage.setItem('callbackRedirectUrl', sessionRedirectUrl)
          const consent = {
            idempotencyKey: uuidv4(),
            sessionId,
            ...data,
          }
          dispatch({ type: ConsentTypes.UPDATE_CONSENT_DETAILS, data: consent })
          return data
        })
        .catch((e: any) => {
          dispatch({
            type: ConsentTypes.ERROR_DETAILS,
            data: {
              title: 'Erro ao consultar as informações da seção',
              statusCode: 400,
              code: 'SES-001',
              message: 'Não foi possível consultar as informações da seção',
            },
          })
          return null
        })
    },
    handleUpdateConsent: (data: any) =>
      dispatch({ type: ConsentTypes.UPDATE_CONSENT_DETAILS, data }),

    handleGraviteeToken: (data: any) =>
      dispatch({ type: Types.UPDATE_GRAVITEE_TOKEN, data }),
    handleSetRedirectUrl: (data: string) =>
      dispatch({ type: Types.SET_REDIRECT_URL, data }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
