import { connect } from 'react-redux'
import Component from './component'

const mapStateToProps = (state: any) => {
  return {
    error: state.error,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    // handleGetPayment: (
    //   consentId: string,
    //   Authorization: string
    // ): Promise<any> => {
    //   return PaymentService.getPixPayment(consentId, Authorization).then(
    //     (data: any) => {
    //       dispatch({ type: Types.STORE_PAYMENT, data })
    //       return data
    //     }
    //   )
    // },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Component)
