import { HighlightOff } from '@mui/icons-material'
import { LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { Button, Container, Typography } from '@mui/material'
import React from 'react'

interface IError {
  title: string
  statusCode: Number
  code?: string
  message?: string
  callStack?: any
  callbackUrl?: string
}
interface IProps {
  error: IError
}

const isAnUnexpectedError = (error: any) => {
  if (
    typeof error === 'undefined' ||
    error?.toLowerCase() === 'internal server error'
  )
    return true
  return false
}

const ErrorPage = ({ error }: IProps) => {
  const _error = {
    ...error,
    ...(window as any).env.errors[error?.code ?? ''],
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container
        maxWidth={'md'}
        className="error-page-container"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <Typography
          variant="h3"
          style={{ textAlign: 'center', margin: 32, fontWeight: 800 }}
          className="error-page-pagetitle"
        >
          {'Erro'}
        </Typography>
        <HighlightOff
          className="error-page-icon"
          color="error"
          style={{ fontSize: 72, textAlign: 'center' }}
        />
        <Typography
          variant="h6"
          style={{ textAlign: 'center' }}
          className="error-page-title"
        >
          {isAnUnexpectedError(_error.title)
            ? 'Ocorreu um erro inesperado'
            : _error.title}
        </Typography>
        <Typography
          variant="body2"
          style={{ textAlign: 'center' }}
          className="error-page-message"
        >
          {isAnUnexpectedError(_error.message) ? '' : _error.message}
        </Typography>
        {_error?.callbackUrl && (
          <Button
            size="large"
            className="error-page-return-button"
            color="primary"
            variant="contained"
            onClick={() => {
              window.location.href = _error?.callbackUrl
                ? `${_error.callbackUrl}?error=${_error.statusCode}`
                : ''
            }}
          >
            Retornar
          </Button>
        )}
      </Container>
    </LocalizationProvider>
  )
}

export default ErrorPage
