import queryString from 'query-string'
import { connect } from 'react-redux'
import PaymentService from '../../services/payment'
import { ConsentTypes, IPaymentConsent } from '../../types'
import Component from './component'

const createConsent = ({
  token,
  organizationId,
  ...consent
}: any): Promise<any> => {
  return PaymentService.createPaymentConsent({
    ...consent,
    token,
    organizationId,
  })
}

const mapStateToProps = (state: any) => {
  return {
    organization: state.selectedHolder,
    consent: state.consent,
    consentError: state.consentError,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    handleSelectMethod: (consent: IPaymentConsent) => {
      dispatch({ type: ConsentTypes.ERROR_DETAILS, data: null })
      return createConsent(consent)
        .then((result: any) => {
          if (result.statusCode?.toString() === '201') {
            dispatch({
              type: ConsentTypes.CREATED_CONSENT_DETAILS,
              data: result,
            })
            return result
          } else {
            const { query, url } = queryString.parseUrl(consent.redirectUrl)
            query.error = encodeURIComponent(
              result.error_description ?? 'Erro ao criar o consentimento'
            )
            query.error = result.statusCode ?? 400
            const parsedQuery = queryString.stringify(query)

            dispatch({
              type: ConsentTypes.ERROR_DETAILS,
              data: {
                ...result,
                ...query,
                title: result.error ?? undefined,
                message: 'Ocorreu um erro ao tentar criar o consentimento',
                callbackUrl: `${url}?${parsedQuery}`,
              },
            })
            return null
          }
        })
        .catch((e: any) => {
          //alert(e)
          dispatch({ type: ConsentTypes.ERROR_DETAILS, data: e })
        })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
