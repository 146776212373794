import React from 'react'
import { useLocation } from 'react-router-dom'

export const mascDocument = (document: string) => {
  if (!document) return
  return document.replace(/^\d{3}|\d{3}$/gi, '***')
}

export function useQuery() {
  const location = useLocation()
  const search = location.search //decodeURIComponent(location.search)
  const result = React.useMemo(() => new URLSearchParams(search), [search])
  return result
}
