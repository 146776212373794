import { DatePicker } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import {
  Button,
  Divider,
  Grid,
  Link,
  Paper,
  TextField,
  Typography
} from '@mui/material'
import Locale from 'date-fns/locale/pt-BR'
import queryString from 'query-string'
import React from 'react'
import env from 'react-dotenv'
import { useHistory } from 'react-router'
import { mascDocument } from '../../helpers'
import { IPaymentConsent } from '../../types'

interface IProps {
  item: any

  organization: any
  consent: IPaymentConsent
  handleSelectHolder: (data: any) => any
}

const PaymentMethodSelector = ({ organization, consent }: IProps) => {
  const history = useHistory()
  const btnCancelarClick = () => {
    const { query, url } = queryString.parseUrl(consent.redirectUrl)
    query.error = 'access_denied'
    query.error_description = 'End-User aborted interaction'
    const parsedQuery = queryString.stringify(query)
    window.location.href = `${url}?${parsedQuery}`
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={Locale}>
      <Typography id="checkout-title" variant="h4">
        Checkout
      </Typography>
      <Grid container spacing={6} id="checkout-container">
        <Grid item xs={12} md={6} id="checkout-left-container">
          <Paper
            style={{ padding: 16 }}
            elevation={4}
            id="checkout-left-container-paper"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} className="checkout-left-container-section">
                <Typography className="label">Valor a pagar</Typography>
                <Typography
                  variant="h5"
                  className="checkout-valor-exibir"
                  id="checkout-valor-a-pagar"
                >
                  R$ {consent.payment.amount.toFixed(2).toString()}
                </Typography>
              </Grid>
              <Grid item xs={12} className="checkout-left-container-section">
                <Divider />
              </Grid>
              <Grid item xs={12} className="checkout-left-container-section">
                <Typography className="label">De:</Typography>
                <Typography variant="body2" className="checkout-valor-exibir">
                  {consent.debtor.name}
                </Typography>
                <Typography variant="body2" className="checkout-valor-exibir">
                  {organization.organizationName}
                </Typography>
              </Grid>
              <Grid item xs={12} className="checkout-left-container-section">
                <Divider />
              </Grid>
              <Grid item xs={12} className="checkout-left-container-section">
                <Typography className="label">Para:</Typography>
                <Typography variant="body2" className="checkout-valor-exibir">
                  {consent.creditor.name}
                </Typography>
                <Typography variant="body2" className="checkout-valor-exibir">
                  {mascDocument(consent.creditor.cpfCnpj)}
                </Typography>
              </Grid>
              <Grid item xs={12} className="checkout-left-container-section">
                <Divider />
              </Grid>
              <Grid item xs={12} className="checkout-left-container-section">
                <Typography className="label">Forma de pagamento:</Typography>
                <Typography variant="body2" className="checkout-valor-exibir">
                  {consent.payment.type}
                </Typography>
                {consent.payment.type === 'PIX' && (
                  <>
                    <Grid item xs={12}>
                      <Typography className="label">Chave PIX:</Typography>
                      <Typography
                        variant="body2"
                        className="checkout-valor-exibir"
                      >
                        {consent.payment.details.proxy}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className="label">Mensagem Pix:</Typography>
                      <Typography
                        variant="body2"
                        className="checkout-valor-exibir"
                      >
                        {consent.remittanceInformation}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item xs={12} className="checkout-left-container-section">
                <Typography className="label">Data de pagamento:</Typography>
                <DatePicker
                  value={consent.payment.date}
                  onChange={() => {}}
                  renderInput={(params) => (
                    <TextField
                      className="field-payment-date"
                      fullWidth
                      {...params}
                    />
                  )}
                  disabled
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} id="checkout-right-container">
          <Paper id="checkout-right-container-paper" elevation={4}>
            <Typography
              variant="h5"
              align="center"
              className="laranja bold"
              id="checkout-right-container-section-title"
            >
              Importante:
            </Typography>
            <Typography
              variant="body2"
              align="justify"
              id="checkout-right-container-text"
            >
              A transação só será efetivada após a autenticação na instituição
              de Débito na condição da existencia de fundos na conta
              selecionada.
            </Typography>
            {env.REACT_APP_ACCEPTANCE_TERMS && (
              <Link
                id="checkout-right-container-terms"
                className="laranja"
                href={`${env.REACT_APP_ACCEPTANCE_TERMS}`}
                target="_blank"
                rel="noreferrer"
                style={{
                  textAlign: 'end',
                }}
              >
                Ler os Termos e Condições
              </Link>
            )}
          </Paper>
          <Grid item xs={12} id="checkout-right-container-button-container">
            <Button size="large" variant="outlined" onClick={btnCancelarClick}>
              Cancelar
            </Button>

            <Button
              size="large"
              variant="contained"
              onClick={() => {
                history.push('/consent-redirect')
              }}
            >
              Continuar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </LocalizationProvider>
  )
}

export default PaymentMethodSelector
