import { Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import OrganizationCard from '../../components/OrganizationCard'
import SearchBar from '../../components/SearchBar'
import { useQuery } from '../../helpers'
import env from 'react-dotenv'

interface IProps {
  item: any
  handleSelectHolder: (data: any) => any
  handleFetchOrganizations: (sessionId: string) => Promise<any[]>

  handleLoadSessionConsent: (
    sessionId: string,
    sessionRedirectUrl: string
  ) => Promise<any[]>
  accountHolders: Array<any>
}

const AccountHolderSelector = ({
  handleSelectHolder,
  handleFetchOrganizations,
  handleLoadSessionConsent,
  accountHolders,
}: IProps) => {
  const history = useHistory()
  const [searchValue, setSearch] = useState<string>('')
  const [showAlertError, setShowAlertError] = useState<boolean>(false)
  const query = useQuery()
  const sessionId: string = query.get('session') ?? ''
  const sessionRedirectUrl: string = query.get('redirectUrl') || ''
  useEffect(() => {
    handleLoadSessionConsent(sessionId, sessionRedirectUrl).then(
      (result: any) => {
        if (!result) {
          history.push('/error')
        }
      }
    )
  }, [handleLoadSessionConsent, history, sessionId, sessionRedirectUrl])

  useEffect(() => {
    handleFetchOrganizations(sessionId).then((result: any) => {
      if (!result) {
        history.push('/error')
      }
    })
  }, [handleFetchOrganizations, history, sessionId])

  const orgs = accountHolders.filter(
    (x: any) =>
      !searchValue ||
      x.organizationName
        .toUpperCase()
        .trim()
        .indexOf(searchValue.toUpperCase()) > -1
  )

  return (
    <Grid container spacing={1} className="account-holder">
      <Grid item xs={12} className="title-container">
        <Typography variant="h4" className="title">
          Selecione a instituição para efetuar o pagamento
        </Typography>
        {!!showAlertError && (
          <Typography
            variant="body1"
            style={{ textAlign: 'center', marginBottom: 20, color: 'red' }}
          >
            Para fins de demonstração, somente o Banco FCamara está disponível
          </Typography>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        className="searchbar-container"
        style={{
          padding: 0,
          marginBottom: 32,
          alignTracks: 'space-between',
        }}
      >
        <SearchBar
          value={searchValue}
          className="searchbar"
          onChange={(newValue: any) => {
            setSearch(newValue.target.value.toString())
          }}
        />
      </Grid>
      <Grid container spacing={2} className="cards-container">
        {orgs.map((item, index) => (
          <OrganizationCard
            className="organization-card"
            key={`${index}-${item.organizationId}`}
            item={item}
            onClick={(data: any) => {
              if (
                // eslint-disable-next-line eqeqeq
                env.REACT_APP_ONLY_FC == 'true' &&
                data.organizationName !== 'Banco FCamara'
              ) {
                setShowAlertError(true)
                return
              }
              handleSelectHolder(data)
              history.push('/select-payment-type')
            }}
          />
        ))}
      </Grid>
    </Grid>
  )
}

export default AccountHolderSelector
